.wrapper {
    position: relative;
    display: flex;
    gap: 12px;
    margin-top: 26px;
}

.image {
    display: block;
    width: 120px;
    height: 120px;
    flex-shrink: 0;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-right: 28px;
}

.title {
    font-family: "Ali Font", Inter, sans-serif;
    font-size: 26px;
    line-height: 26px;
    font-weight: 700;
    text-align: left;
    color: #18181b;
    text-transform: uppercase;
}

.buttons {
    display: flex;
    gap: 4px;
}

.button {
    background-color: #ffed96;
    color: #18181b;

    &Icon {
        color: #767058;
        width: 12px;
        height: 12px;
    }

    @media (pointer: fine) {
        &:hover {
            background-color: #fdf1b6;
        }
    }

    &:active,
    .active {
        background-color: #fbe476;
    }
}

.likeButton {
    display: flex;
    align-items: center;
    z-index: 10;
    padding: 0;

    border: none;
    border-radius: 100em;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}
.likeButtonText {
    -webkit-line-clamp: 1;
    color: #898993;
    padding: 0 4px 2px 4px;
    text-align: center;
    text-overflow: ellipsis;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.13px;
}
.likeButtonTextLiked {
    color: #fe2722;
}
.toast {
    bottom: 24px;
    left: 24px;
    top: unset;
    margin: 0;
}
.likeButton:hover .likeButtonText {
    color: #547dff;
}
.likeButton:hover > svg > path {
    fill: #547dff;
}
.buttonsContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
    z-index: 10;
}
.sharingBlock {
    position: relative;
    z-index: 100000;
}
@media (max-width: 768px) {
    .likeButton {
        display: none;
    }
}
