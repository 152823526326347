.tooltipWrap {
    position: absolute;
    bottom: -8px;
    right: 0;
    display: flex;
    opacity: 0;
    pointer-events: none;
    transform: translate(0, 100%);

    @media (min-width: 767px) {
        transform: translate(15px, 100%);
    }

    &.en_US {
        @media (min-width: 767px) {
            transform: translate(50px, 100%);
        }
    }
}

.showTooltip {
    opacity: 1;
    pointer-events: auto;
}

.contentWrap {
    position: relative;
    padding: 16px;
    border-radius: 8px;
    background-color: #ffffff;
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.12));

    &::before {
        content: "";
        position: absolute;
        top: -8px;
        right: 0;
        display: block;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 20px solid #ffffff;

        @media (min-width: 767px) {
            right: 50px;
        }
    }
}

.title,
.buttonsWrap {
    padding-bottom: 12px;
}

.closeWrp {
    position: absolute;
    top: 4px;
    right: 4px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 100px;
    padding: 8px;
}

.closeIcon {
    color: #6c727e;
}
