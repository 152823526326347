.copyButton {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 6px;
    background-color: #eef2f6;
    cursor: pointer;
}

.copyIcon {
    display: block;
    height: 24px;
    width: 24px;
    color: #6c727e;
}

.disabled {
    background-color: #f8f9fb;
    color: #a0a0ab;
}
