.contentRootClassName {
    background-color: #ffe16e;
    padding: 0;
}

.withTopAnchor {
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    width: 42px;
    height: 4px;
    border-radius: 101px;
    background: #d1d1d6;

    @media (--snow-tablet) {
        display: none;
    }
}

.className {
    height: 100%;
    z-index: 1043;

    @media (--snow-tablet) {
        width: 100%;
        max-width: 461px;
        inset: 20px 20px 20px unset;
        transform: unset;
        height: unset;
        background-color: #ffe16e;
        border-radius: 6px;
        padding: 0 16px 16px 16px;

        & > div {
            height: 100%;
            /*Шапка*/
            & > div:nth-child(1) {
                display: none;
            }
            /*Контент*/
            & > div:nth-child(2) {
                height: 100%;
                scrollbar-width: none;
                -ms-overflow-style: none;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }
}

.headerClassName {
    /*Крестик*/
    & > div {
        background-color: #ffed96;
        /*Иконка крестика*/
        & > svg {
            stroke: #726430;
        }
    }
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.loader {
    width: 40px;
    height: 40px;
    border-color: #e6eaf0 !important;
    border-top-color: #6c727e !important;
    border-style: solid;
    border-radius: 100%;
    border-width: 10px;
    animation: spin 4s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.hidden {
    display: none;
}

.closeButton {
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 1043;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    padding: 8px;
    background-color: #ffed96;

    @media (--snow-tablet) {
        top: 36px;
        right: 36px;
    }
}

.iconDismiss {
    width: 12px;
    height: 12px;
    stroke: #726430;
    stroke-width: 3;
}
