.wrapper {
    position: relative;
    padding: 0 12px;

    @media (--snow-tablet) {
        padding: 0;
    }
}

.hide {
    display: none;
}

.galleryWrapper {
    margin-top: 8px;
    border-radius: 12px 12px 0 0;
}

.counter {
    position: absolute;
    bottom: 10px;
    right: 50%;
    transform: translateX(50%);
    color: #ffffff;
    background-color: #22222399;
    padding: 3px 6px;
    border-radius: 80px;
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    text-align: center;

    @media (--snow-tablet) {
        bottom: 13px;
        padding: 4px 9px;
        border-radius: 115px;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
    }
}
