.socialsWrap {
    display: flex;
    padding-bottom: 12px;
}

.socialItemWrap {
    display: inline-flex;
    overflow: hidden;
    height: 48px;
    width: 48px;
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 10px;
    }

    @media (min-width: 767px) {
        &:not(:last-child) {
            margin-right: 14px;
        }
    }
}

.socialIcon {
    height: 100%;
    width: 100%;
}
