.wrapper {
    padding: 0 12px;

    @media (--snow-tablet) {
        padding: 0;
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 17px;
    padding: 12px 16px;
    border-radius: 0 0 12px 12px;
    background-color: #ffffff;
}

.price {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &Primary {
        font-family: "Ali Font", Inter, sans-serif;
        font-size: 32px;
        font-weight: 700;
        text-align: left;
        &Value {
            color: #ff6437;
        }
        &Stars {
            display: inline-flex;
            align-items: baseline;
            gap: 2px;
        }
    }

    &Secondary {
        display: flex;
        gap: 3px;

        &Value {
            color: #898993;
        }
        &Discount {
            color: #ff6437;
        }
    }
}

.delivery {
    display: flex;
    align-items: center;
}

.free {
    color: #00ad30;
    text-transform: lowercase;
}

.skeleton {
    display: inline-flex;
    width: 132px;
    height: 16px;
    position: relative;
    overflow: hidden;
    animation: pulse 1.5s ease-in-out infinite; /* Анимация для пульсации */
    background-color: #eef2f6;
    border-radius: 4px;
}

/* Стили для псевдоэлемента, который создает анимированную полоску */
.skeleton::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.8), transparent);
    animation: slide 1.5s linear infinite; /* Анимация для полоски */
}

/* Анимация для пульсации */
@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

/* Анимация для движения полоски */
@keyframes slide {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}

.product {
    display: flex;
    flex-direction: column;
    gap: 6px;

    &Trust {
        display: flex;
        gap: 5px;

        &RatingMiddle {
            display: flex;
            align-items: center;
            gap: 4px;
        }

        &StarsIcon {
            color: #ff6c02;
        }

        &Item {
            color: #898993;
        }
    }
}
