:root {
    --scroll-gap: 16px;
}

.snippetWrapper {
    overflow: hidden;
    max-width: calc(100% + var(--scroll-gap) * 2);
    margin-top: 32px;
    margin-bottom: 24px;
    position: relative;
}

.snippetContent {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    overflow-x: scroll;
    padding: 0 var(--scroll-gap);

    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    user-select: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.controlls {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding: 0 12px;
}

.controll {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}

.snippet {
    width: 160px;
    background-color: #fff;
    min-width: 160px;
    border-radius: 6px;
    overflow: hidden;
}

.snippetImage {
    max-width: 100%;
    vertical-align: top;
}

.priceBlock {
    padding: 8px 8px 0px;
    display: flex;
    align-items: flex-start;
    gap: 4px;
    margin-bottom: 4px;
    font-family: "Ali Font", Inter, sans-serif;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    color: #000;
}

.price {
    color: #ff6437;
}

.starsWrapper {
    display: flex;
    align-items: baseline;
    gap: 2px;
}

.secondPriceBlock {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    padding: 0 8px 8px;
}

.secondPrice {
    color: #898993;
    text-decoration: line-through;
}

.discount {
    color: #18181b;
}

.hide {
    visibility: hidden;
}

@media (hover: hover) {
    .controlls {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .controll {
        background-color: #f3f3f3;
    }
}
